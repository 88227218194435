// src/pages/BlogPost.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const BlogPost: React.FC = () => {
  const { id } = useParams(); // e.g., "my-first-post"
  const [content, setContent] = useState('');

  useEffect(() => {
    // Example: fetch('/posts/my-first-post.md')
    fetch(`/posts/${id}.md`)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((error) => {
        // handle error (e.g., post not found)
        console.error(error);
        setContent('# Post Not Found\nThe post you are looking for does not exist.');
      });
  }, [id]);

  return (
    <div className="prose mx-auto p-4">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default BlogPost;
