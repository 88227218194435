
const About = () => {
  return (
    <div style={{ padding: '1rem' }}>
      <h1>About Me</h1>
      <p>A brief introduction about me and my background.</p>
    </div>
  );
};

export default About;