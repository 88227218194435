// src/pages/Blog.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const posts = [
  { id: '2024-reflection', title: '2024 Year in Review: Adaptation' },
];

const Blog = () => {
  return (
    <div style={{ padding: '1rem' }}>
      <ul>
        {posts.map((post) => (
          <li key={post.id}>
            <Link
              to={`/blog/${post.id}`}
              className="text-blue-600 underline hover:text-blue-800"
            >
              {post.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blog;
