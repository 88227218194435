// src/pages/Home.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const featuredPosts = [
  {
    id: '2024-reflection',
    title: '2024 Year in Review: Adaptation',
    description: 'Looking back on the year of change and adaptation.',
    image: 'https://cdn.pixabay.com/photo/2024/07/02/08/55/new-years-day-8867134_640.jpg',
  },
];


const Home: React.FC = () => {
  return (
    <div className="w-full">
      {/* Interactive Dots Hero Section */}
      {/* <InteractiveDotsHero />
   */}
      {/* Featured Posts / About Section */}
      <section className="py-12 px-4 bg-white">
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-row gap-8">
            {/* Left Column (About) */}
            <div className="w-2/3">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
                About
              </h2>
              <p className="text-gray-700 leading-relaxed">
                {/* Replace this with your personal about info */}
                I'm David -- welcome!<br /><br />

                My background is in software engineering. I studied Computer Science at University of Illinois at Urbana Champaign, where I interned at places such as M1 Finance, Enfusion, and Amazon Web Services.
                My second semester junior year of college was spent at the National University of Singapore.<br /><br />

                After college, I worked at Amazon Web Services in Seattle on the Elastic Block Store Placement team. We were responsible for optimizing the placement of EBS volumes across our hardware fleet.<br /><br />

                I left AWS to cofound a company in the social commerce space. We were backed by General Advance and headquarted in Los Angeles, CA. After just shy of two years of building and iterating, I reached irreconcilable differences with my cofounder and left the company.<br /><br />

                Now, I live in San Francisco and work for Merge as a software engineer.<br /><br />

                In my free time, I enjoy playing piano and guitar, snowboarding, and reading biographies and philosophy.
              </p>
            </div>

            {/* Right Column (Latest Posts) */}
            <div className="w-1/3">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Latest Posts
              </h2>
              <div className="space-y-4">
                {featuredPosts.map((post) => (
                  <Link
                    key={post.id}
                    to={`/blog/${post.id}`}
                    className="block group bg-gray-50 border border-gray-200 
                               rounded-md shadow-sm hover:shadow-md transition 
                               overflow-hidden"
                  >
                    <div className="h-40 overflow-hidden">
                      <img
                        src={post.image}
                        alt={post.title}
                        className="w-full h-full object-cover 
                                   group-hover:scale-105 transition"
                      />
                    </div>
                    <div className="p-4">
                      <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600">
                        {post.title}
                      </h3>
                      <p className="text-sm text-gray-600 mt-2">
                        {post.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer (Optional) */}
      <footer className="text-gray-500 py-6 text-center">
        <p className="text-sm">
          © {new Date().getFullYear()} David Dalmaso. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default Home;
