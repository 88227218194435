import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isShrunk, setIsShrunk] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      // If scrolled more than 50px from top, shrink the navbar
      if (window.scrollY > 60) {
        setIsShrunk(true);
      } else {
        setIsShrunk(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='w-full flex flex-row justify-center items-center'>
    <nav
      className={`
        fixed top-0 w-full z-50 bg-white transition-all duration-500 
        ${isShrunk ? 'mt-4 w-2/3 py-4 shadow-lg' : 'py-4 mt-4'}
      `}
    >
      <div className="max-w-4xl mx-auto flex items-center justify-center px-1">
        <Link to="/" className="text-xl font-semibold text-gray-800">
          David Dalmaso
        </Link>
      </div>
    </nav>
    </div>
  );
}

export default Navbar;
