// src/App.tsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Navbar from './components/Navbar';
import './App.css';

function App() {
  return (
    <Router>
      {/* The new shrinking navbar */}
      <Navbar />

      {/* Add some top padding so content starts below the fixed nav */}
      <main className="mx-auto max-w-5xl p-4 pt-20">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
